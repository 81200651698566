section.referenzenchefig {
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &.white {
    h1, h2, h3, p, a {
      color: $white;
    }
  }
}