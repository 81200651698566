section.contact {
  .form-footer {
    margin-bottom: 25px;
    input {
      border: none;
      background: none;
      margin: 0;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;
      text-align: left;
      padding: 10px 0;
      outline: none;
      cursor: pointer;
      @include breakpoint(small only) {
        font-size: 16px;
      }
    }
  }
  .ui-input {
    position: relative;
    padding: 0;
    border: 0;
  }

  .ui-input input, .ui-input textarea{
    border: 0;
    background: none;
    padding: 16px 0 16px 0;
    font-size: 24px;
    outline: 0;
    width: 100%;
    tap-highlight-color: rgba(0, 0, 0, 0);
    touch-callout: none;
    @include breakpoint(small only) {
      font-size: 18px;
    }
  }

  .ui-input input + label, .ui-input textarea + label {
    position: relative;
    display: block;
    padding: 8px 0 8px 0;
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    @include breakpoint(small only) {
      font-size: 16px;
    }
  }
  .ui-input input + label::before, .ui-input input + label::after, .ui-input textarea + label::after, .ui-input textarea + label::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
  }
  .ui-input input + label::before, .ui-input textarea + label::before {
    background-color: $grablack;
  }
  .ui-input input + label::after, .ui-input textarea + label::after {
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    //background-color: $gracolor;
    height: 2px;
  }
  .ui-input input + label span, .ui-input textarea + label span {
    position: relative;
    color: $grablack;
    transition: color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .ui-input input + label span::after, .ui-input textarea + label span::after {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    left: 0;
    top: -4px;
    transform: scaleX(1);
    white-space: nowrap;
    color: $grablack;
    background-image: linear-gradient(to right, $gracolor 50%, rgba(255, 255, 255, 0) 0%);
    background-position: 100% 50%;
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    backface-visibility: hidden;
    perspective: 1000;
    transform: translateZ(0);
    transition: background-position 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .ui-input input:focus + label::after,
  .ui-input input.error + label::after,
  .ui-input input:invalid + label::after,
  .ui-input input.filled + label::after,
  .ui-input textarea:focus + label::after,
  .ui-input textarea.error + label::after,
  .ui-input textarea:invalid + label::after,
  .ui-input textarea.filled + label::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  .ui-input input:focus, .ui-input textarea:focus {
    color: $gracolor;
  }
  .ui-input input:focus + label::after, .ui-input textarea:focus + label::after {
    background-color: $gracolor;
  }
  .ui-input input:focus + label span::after, .ui-input textarea:focus + label span::after {
    background-image: linear-gradient(to right, $gracolor 50%, rgba(255, 255, 255, 0) 0%);
    background-position: 0% 50%;
  }

  .ui-input input.error,
  .ui-input input:invalid,
  .ui-input textarea.error,
  .ui-input textarea:invalid{
    color: #E66161;
  }
  .ui-input input.error + label::after,
  .ui-input input:invalid + label::after,
  .ui-input textarea.error + label::after,
  .ui-input textarea:invalid + label::after{
    background-color: #E66161;
  }
  .ui-input input.error + label span::after,
  .ui-input input:invalid + label span::after,
  .ui-input textarea.error + label span::after,
  .ui-input textarea:invalid + label span::after{
    background-image: linear-gradient(to right, #E66161 50%, rgba(255, 255, 255, 0) 0%);
    background-position: 0% 50%;
  }

  .__first, .__second, .__third, .__fourth {
    animation-name: fadeIn;
    animation-duration: 180ms;
    animation-fill-mode: both;
    animation-iteration-count: 1;
  }

  .__first {
    animation-delay: 0;
  }

  .__second {
    animation-delay: 80ms;
  }

  .__third {
    animation-delay: 180ms;
  }

  .__fourth {
    animation-delay: 360ms;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

}