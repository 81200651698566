@font-face {
  font-display: swap;
  font-family: 'fontello';
  src: url('../20_fonts/fontello.eot?jd3tgj');
  src: url('../20_fonts/fontello.eot?jd3tgj#iefix') format('embedded-opentype'),
  url('../20_fonts/fontello.woff?jd3tgj') format('woff'),
  url('../20_fonts/fontello.ttf?jd3tgj') format('truetype'),
  url('../20_fonts/fontello.svg?jd3tgj#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?26394516#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


.icon-vimeo:before {content: "\eaa0";}
.icon-linkedin:before {content: "\eaca";}
.icon-xing:before {content: "\ead4";}
.icon-keyboard_arrow_left:before {content: "\e800";}
.icon-keyboard_arrow_right:before {content: "\e801";}
.icon-graustich_logo:before {content: "\e806";}
.icon-webdevelopment_icon:before {content: "\e807";}
.icon-augmentedreality_icon:before {content: "\e808";}
.icon-VirtualReality_icon:before {content: "\e809";}
.icon-AppDevelopment_icon:before {content: "\e80a";}
.icon-IOS_icon:before {content: "\e80b";}
.icon-react_icon:before {content: "\e80c";}
.icon-android_icon:before {content: "\e80d";}
.icon-cloud_icon:before {content: "\e80e";}
.icon-twitter:before {content: "\f099";}
.icon-facebook:before {content: "\f09a";}
.icon-youtube-play:before {content: "\f16a";}
.icon-instagram:before {content: "\f16d";}