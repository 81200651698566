section.newsfooter {
  .tags {
    width: 100%;
    float: left;
    border-bottom: 1px solid $grablack;
    a{
      padding: 5px 20px;
      background-color: #E4E4E4;
      transition: all 250ms;
      margin-right: 25px;
      margin-top: $abstand;
      margin-bottom: $abstand;
      @include breakpoint(small only) {
        margin-top: $abstandSmall;
        margin-bottom: $abstandSmall;
      }
      color: $grablack;
      border-radius: 2px;
      display: inline-block;
      font-size: 20px;
      &:hover {
        background-color: $gracolor;
        color: $white;
      }
    }
  }
  .social {
    width: 100%;
    float: left;
    border-bottom: 1px solid $grablack;
    a {
      text-align: center;
      background-color: #E4E4E4;
      transition: all 250ms;
      margin-right: 25px;
      margin-top: $abstand;
      margin-bottom: $abstand;
      @include breakpoint(small only) {
        margin-top: $abstandSmall;
        margin-bottom: $abstandSmall;
      }
      display: inline-block;
      width: 84px;
      height: 58px;
      font-size: 43px;
      border-radius: 2px;
      i {
        color: #969696;
        top: -3px;
        position: relative;
      }
      &:hover {
        background-color: $gracolor;
        i {
          color: $white;
        }
      }
    }
  }
  .back {
    padding-top: 40px;
    border-top: 1px solid $grablack;
    @include breakpoint(small only) {
      padding-top: $abstandSmall;
    }
    a {
      color: $grablack;
      font-size: 20px;
      font-weight: 600;
      display: inline-block;
      width: 100%;
      opacity:0.5;
      transition: all 250ms;
      &:hover {
        opacity: 1;
      }
    }
  }
}