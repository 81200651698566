section.leistung {
  padding-top: $abstand;
  background-color: #E5E3E3;
  @include breakpoint(small only) {
    padding-top: $abstandSmall;
  }
  a {
    //color: $gracolor;
    &:hover {
      //text-decoration: underline;
    }
  }
  p, h3, h4, ul, li {
    color: #575756;
  }
  h3 {
    text-transform: none;
  }
  ul {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: $abstand / 2;
    @include breakpoint(small only) {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
  li {
    position: relative;
    list-style-type: none;
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 0;
      width: 9px;
      height: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../10_images/02_elements/liststyle.svg');
    }
    a {
      color: #575756;
    }
    font-size: 18px;
    border-bottom: 1px solid rgba(38, 38, 38, 0.12);
    padding: 10px 0 10px 18px;
    @include breakpoint(medium only) {
      font-size: 18px;
    }
    @include breakpoint(small only) {
      font-size: 16px;
    }
  }

}