section.overview {
  padding-bottom: $abstand;
  h1, h2.h1 {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: $abstand - 10;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
    span {
      color: $gracolor;
    }
  }
  h2, p.bold {
    margin-top: $abstand;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
    margin-bottom: 0;
  }
}