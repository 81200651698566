.container {
  //padding: 10px;
  //border: 2px solid #F90;
  position: relative;
  height: 500px;
  width: 100%;
  @include breakpoint(medium down) {
    height: 500px;
  }
  @include breakpoint(small only) {
    height: 450px;
  }
  margin-bottom: 25px;
}

.draggable {
  //width: 140px;
  //height: 140px;
  //background: #F90;
  //border-radius: 10px;
  //margin: 0 10px 10px 0;
  //float: left;
  cursor: all-scroll;
  &.point {
    width: 66px;
    height: 66px;
  }
  &.point1 {
    left: 25%;
    top: 10%
  }
  &.point2 {
    left: 50%;
    top: 5%
  }
  &.point3 {
    right: 5%;
    bottom: 5%
  }
  &.point4 {
    left: 10%;
    bottom: 7%
  }
  &.point5 {
    right: 25%;
    bottom: 13%
  }
}
.draggable.is-pointer-down {
  //background: #09F;
  z-index: 2; /* above other draggies */
}
.draggable.is-dragging { opacity: 0.7; }