.imgtxt, .txtimg {
  .lazysizewrapper {
    margin-top: 40px;
  }
  .cell {
    padding-bottom: 1rem;
  }
  ul {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 1rem;
    @include breakpoint(small only) {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
}