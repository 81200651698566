section.imgmatrix {
  h2 {
    margin-top: 0;
    font-size: 44px;
    @media only screen and (max-width: 1650px) and (min-width: 1200px) {
      font-size: 30px;
    }
    @include breakpoint(medium only) {
      font-size: 40px;
      margin-top: $abstand;
    }
    @include breakpoint(small only) {
      font-size: 20px;
      margin-top: $abstandSmall;
    }
  }
  p {
    font-weight: 600;
    margin-top: $abstand;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
    @media only screen and (max-width: 1650px) and (min-width: 1200px) {
      font-size: 20px;
    }
    @include breakpoint(medium only) {
      font-size: 22px;
    }
    @include breakpoint(small only) {
      font-size: 16px;
    }
  }
  a.button {
    @include breakpoint(medium only) {
      margin-bottom: $abstand;
    }
    @include breakpoint(small only) {
      margin-bottom: $abstandSmall;
    }
  }

  a.over {
    display: none;
  }

  @include  breakpoint(large up) {
    a.over {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 40;
      display: block;
    }
    a.over:hover {
      &~.overlay {
        opacity: 1;
      }
    }
  }

  .overlay {
    padding-right: .625rem;
    padding-left: .625rem;
  }
  .cell {
    position: relative;
  }
  @include  breakpoint(large up) {
    .overlay {
      transition: 300ms all;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background:rgba(255,255,255,0.90);
      opacity: 0;
      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}