

@if index($hamburger-types, arrowspin) {
  /*
   * Arrowspin
   */
  .hamburger--arrowspin {
    .hamburger-box {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transform: rotate(0deg);
    }
  }
  .hamburger--arrowspin.is-active {
    .hamburger-box {
      transform: rotate(360deg);
    }
    .hamburger-inner {
      &::before {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      &::after {
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, arrowspin-r) {
  /*
   * Arrowspin Right
   */
  .hamburger--arrowspin-r {
    .hamburger-box {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transform: rotate(0deg);
    }
  }
  .hamburger--arrowspin-r.is-active {
    .hamburger-box {
      transform: rotate(360deg);
    }
    .hamburger-inner {
      &::before {
        transition-duration: 0.5s;
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }

      &::after {
        transition-duration: 0.5s;
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }
    }
  }
}

@if index($hamburger-types, graustich) {
  .hamburger--graustich {
    .hamburger-box {
      width: 40px;
      height: 30px;
      .hamburger-1 {
        width: 20px;
        height: 4px;
        background-color: $gracolor;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        position: absolute;
        transition: all 300ms;
        left: 0;
      }
      .hamburger-2 {
        width: 20px;
        height: 4px;
        background-color: $gracolor;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        left: 20px;
        transition: all 300ms;
      }
      .hamburger-3 {
        width: 40px;
        height: 4px;
        background-color: $gracolor;
        border-radius: 4px;
        position: absolute;
        top: 10px;
        left: 0;
        transition: all 300ms;
      }
      .hamburger-4 {
        width: 20px;
        height: 4px;
        background-color: $gracolor;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        position: absolute;
        top: 20px;
        left: 0;
        transition: all 300ms;
      }
      .hamburger-5 {
        width: 20px;
        height: 4px;
        background-color: $gracolor;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        position: absolute;
        left: 20px;
        top: 20px;
        transition: all 300ms;
      }
    }
    .hamburger-box.start {
      animation-name: material-design-hamburger__icon--slide-from;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  .hamburger--graustich.is-active {
    .hamburger-box {
      animation-name: material-design-hamburger__icon--slide;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      .hamburger-1 {
        width: 10px;
        height: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        left: 5px;
      }
      .hamburger-2 {
        width: 10px;
        height: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        left: 25px;
      }
      .hamburger-3 {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 10px;
        left: 15px;
      }
      .hamburger-4 {
        width: 10px;
        height: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        left: 5px;
      }
      .hamburger-5 {
        width: 10px;
        height: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        left: 25px;
      }
    }
  }

  @keyframes material-design-hamburger__icon--slide {
    0% {
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes material-design-hamburger__icon--slide-from {
    0% {
      transform: rotate(-180deg);
    }
    100% {
    }
  }

}


@if index($hamburger-types, material) {
  /*
   * material
   */
  .hamburger--material {
    .hamburger-box.start {
      animation-name: material-design-hamburger__icon--slide-from;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  .hamburger--material.is-active {
    .hamburger-box {
      animation-name: material-design-hamburger__icon--slide;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
    .hamburger-inner {
      &::before {
        transition-duration: 300ms;
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }

      &::after {
        transition-duration: 300ms;
        transform: translate3d($hamburger-layer-width * 0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }
    }
  }

  @keyframes material-design-hamburger__icon--slide {
    0% {
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes material-design-hamburger__icon--slide-from {
    0% {
      transform: rotate(-180deg);
    }
    100% {
    }
  }
}


@if index($hamburger-types, material-r) {
  /*
   * Material Right
   */
  .hamburger--material-r {
    .hamburger-box.start {
      animation-name: material-design-hamburger__icon--slide-from;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }
  .hamburger--material-r.is-active {
    .hamburger-box {
      animation-name: material-design-hamburger__icon--slide;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
    .hamburger-inner {
      &::before {
        transition-duration: 300ms;
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
      }

      &::after {
        transition-duration: 300ms;
        transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
      }
    }
  }

  @keyframes material-design-hamburger__icon--slide {
    0% {
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes material-design-hamburger__icon--slide-from {
    0% {
      transform: rotate(-180deg);
    }
    100% {
    }
  }
}


