section {
  padding-bottom: $abstand;
  @include breakpoint(small only) {
    padding-bottom: $abstandSmall;
  }
  &.imgmatrix, &.youtube, &.bigimg, &.contactCallToAction {
    padding-bottom: 0;
  }

  li {
    position: relative;
    list-style-type: none;
    &::before {
      content: "";
      position: absolute;
      top: 14px;
      left: 0;
      width: 9px;
      height: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url('../10_images/02_elements/liststyle-black.svg');
    }
    a {
      color: $gracolor;
      &:hover {
        text-decoration: underline;
      }
    }
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 6px 0 6px 18px;
    @include breakpoint(medium only) {
      font-size: 18px;
    }
    @include breakpoint(small only) {
      font-size: 16px;
    }
  }
}

section > section:first-child {
  margin-top: 0;
}

h1, h2, h3, h4, p {
  span {
    color: $gracolor;
  }
  margin-bottom: 0;
}

h1, h3 {
  text-transform: uppercase;
  font-weight: 700;
}


h2, h4 {
  font-weight: 600;
}
h4 {
  font-weight: 600;
}
p {
  width: 100%;
  font-size: 16px;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  @include breakpoint(medium only) {
    font-size: 16px;
  }
  @include breakpoint(small only) {
    font-size: 16px;
  }
}


p.bold {
  font-size: 17px;
  font-weight: 600;
  @include breakpoint(medium only) {
    font-size: 18px;
  }
  @include breakpoint(small only) {
    font-size: 16px;
  }
}


a {
  color: $gracolor;
  &:hover, &:active, &:focus {
    text-decoration: underline;
    color: $gracolor;
    outline: none;
  }
}

.btn {
  text-align: center;
}
a.button:hover{

  color: $gracolor !important;
}

a.button, button.button {
  margin: $abstand auto 0 auto;
  padding: 15px 40px;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  color: $grablack;
  text-decoration: none;
  cursor: pointer;

  &.hasSvg{

      overflow: visible;

    svg{
      rect{
        transition: all 600ms ease;
      }

      stroke-width: 2;
      overflow: visible;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @include breakpoint(small only) {
    font-size: 14px;
  }
}


a.button, button.button {
  //transition: all 0.5s;
  position: relative;
  overflow: hidden;
}
a.button:hover, button.button:hover{
  border: 0;
}
/*
a.button::before, button.button::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  transition: all 0.5s;
  transform: translate(-100%, -600%)  rotate(30deg);
  background-color: $grablack;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
*/

/*
a.button.invert::before, button.button.invert::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  transition: all 0.5s;
  transform: translate(-100%, -600%)  rotate(30deg);
  background-color: $gracolor;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

a.button:hover::before, button.button:hover::before{
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
}
*/


body {
  overflow: hidden;
}

main {
  h4.h2 {
    @extend h2;
    text-transform: none;
  }
  h3.h2 {
    @extend h2;
    text-transform: none;
  }
  h1, h2.h1 {
    line-height: 1.2;
    margin-top: $abstand - 10;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
    text-transform: uppercase;
    font-weight: 700;
    font-size: 45px;
    @include breakpoint(medium only) {
      font-size: 36px;
    }
    @include breakpoint(small only) {
      font-size: 30px;
    }
  }
  h2, p.bold, h3, h4, p {
    margin-top: $abstand - 10;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.menu-point {

  position: fixed;
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
  background-color: #222222;
  transition: opacity 350ms ease 130ms, width 500ms ease 0ms;

  @include breakpoint(small only) {

  }
  &.open {
    width: 100%;
    opacity: 1;
    transition: opacity 250ms linear, width 500ms ease;
    transition-delay: 250ms;
  }
}
@keyframes menu-point-close {
  0%{
    visibility: visible;
  }

  99% {
    visibility: visible;
  }
  100%{
    visibility: hidden;
  }
}

.preline {
  font-size:24px;
  margin-top: $abstand;
  @include breakpoint(small only) {
    margin-top: $abstandSmall;
  }
  margin-left: 2px;
  @include breakpoint(small only) {
    font-size: 18px;
  }
  &+h1 {
    margin-top: 5px;
    @include breakpoint(medium down) {
      margin-top: 50px;
    }
    @include breakpoint(small only) {
      margin-top: 15px;
    }
  }
  @include breakpoint(medium down) {
    margin-top: 50px;
  }
}

section > .grid-container {
  section > .grid-container {
    padding: 0 !important;
  }
}

.breadcrumbs {
  margin-top: 40px;
  font-size: 17px;
  color: lighten( $black, 10% );
  a {
    color: lighten( $gracolor, 5% );
    &:hover {
      text-decoration: underline;
    }
  }
}

.hide-for-medium-down {
  @include breakpoint(medium down) {
    display: none;
  }
}

.margin-bottom-medium-down {
  margin-bottom: 50px;
}

.nomargin {
  margin: 0 !important;
}
.marginauto {
  margin: auto !important;
}
