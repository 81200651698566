footer {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: $grablack;
  .grid-x {
    &:first-of-type {
      border-bottom: 1px solid $white;
    }
  }
  a {
    color: $white;
    &:hover, &:active, &:focus {
      color: $white;
      outline: none;
    }
  }
  p {
    color: $white;
    font-size:17px;
    clear: both;
    margin-bottom: 0;
    margin-top: 25px;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  ul {
    margin-left: 0;
    list-style: none;
    margin-bottom: 50px;
    li {
      color: $white;
      list-style: none;
      font-size: 17px;
      margin-bottom: 10px;
      @include breakpoint(medium down) {
        text-align: center;
      }
    }
  }

  ul.level1 > li:first-of-type {
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  ul.level2 {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 0.75rem;
    li {
      margin-bottom: 5px;
    }
    a {
      font-size: 15px;
      opacity: 0.7;
    }

  }
  .logo {
    max-width: 155px;
    margin: 0;
    display: block;
    @include breakpoint(medium down) {
      margin: 0 auto;
    }
  }
  .socialmedia {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    a {
      color: $white;
      font-size: 40px;
      display: inline-flex;
      text-decoration: none;
      text-align: center;
    }
  }
  .impressum a {
    text-decoration: underline;
  }
}
