.gra-accordion.js-accordion {
  position: relative;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .gra-accordion-title{

      padding: 0 12px;

  }
  .gra-accordion-text{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 12px;

  }
  .js-accordion-head {
    cursor: pointer;
  }

  .js-accordion-head {
    p {
      font-size: 16px;
      color: #707070;
      align-self: center;
      width: 90%;
    }

    h4 {
      font-size: 28px;
      align-self: normal;
    }

    margin-bottom: 15px;

    .gra-accordion-text {
      flex-direction: column;
    }

    .js-accordion-plus {

      text-align: center;
      margin-left: auto;
      .menu-plus {

        display: inline-block;
        position: relative;
        left: 5%;
        top: 50%;
        height: 17px;
        width: 17px;
        @include breakpoint(small down){
          position: absolute;
          top: 35px;
          right: 10%;
          left: auto;
        }

        span {
          position: absolute;
          top: calc(50%);
          width: 17px;
          height: 3px;
          display: inline-block;
          background: #BE7956;
          margin: 0;
          padding: 0;
          border-radius: 12px;
        }

        .menu-plus-line-1 {
          transform-origin: center center;
          transform: scaleY(1) rotateZ(90deg);
          transition: width 300ms ease-in-out, transform 300ms ease-in-out;

        }

        .menu-plus-line-2 {

        }
      }
      &.open > .menu-plus{
        span {

        }

        .menu-plus-line-1 {

          transform: scaleY(0) rotateZ(90deg);
        }

        .menu-plus-line-2 {

        }
      }
    }


  }


  .js-accordion-content {

    box-sizing: border-box;
    cursor: auto;
    &.closed {

    }

    &.open {

    }

    &.grid-container {

      a {
        margin: 12px 0;
      }
    }
    & > div{
      padding-bottom: 24px !important;
      padding-top: 24px !important;
    }
    height: 0;
    overflow: hidden;
    transition: all 400ms ease-in-out;


    &.open {

      max-height: none;


    }
  }
}