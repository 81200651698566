.swiper-yt .play,.youtube-player .play {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px;
  transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1);
  cursor: pointer;
  display: none;
  .svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &:hover {
    .ytp-large-play-button-bg {
      transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
      fill: $gracolor;
      fill-opacity: 1;
    }
  }
}
.swiper-yt .play {
  display: block;
}

.swiper-yt img ,.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: .4s all;
  -moz-transition: .4s all;
  transition: .4s all;
}
.youtube-player img:hover ~ .play, .swiper-yt img:hover ~ .play {
  .ytp-large-play-button-bg {
    transition: fill .1s cubic-bezier(0.0,0.0,0.2,1),fill-opacity .1s cubic-bezier(0.0,0.0,0.2,1);
    fill: $gracolor;
    fill-opacity: 1;
  }
}

.swiper-yt {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: all .4s;
  transition: all .4s;
}


.responsive-embed {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  background-size: cover;
  &.widescreen {
    padding-bottom: 35.25%;
  }
  .closewrapper {
    .closebtn {
      display: none;
    }
    &.videofixed {
      position: fixed;
      height: 268px;
      right: 32px;
      bottom: 10px;
      width: 476px;
      z-index: 100;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
      opacity: 0;
      &.show {
        opacity: 1;
      }
      &.trans {
        transition: opacity 0.3s linear;
      }
      .closebtn {
        position: absolute;
        right: -25px;
        padding: 0 5px;
        display: block;
        cursor: pointer;
        background-color: rgba(14, 70, 143,.3);
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
        i {
          color: white;
          position: relative;
          top: 2px;
        }
      }
      @include breakpoint(medium only) {
        width: 370px;
        height: 208px;
      }
      @include breakpoint(small only) {
        width: 200px;
        height: 113px;
      }
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

/*section.youtube.responsive {
  .responsive-embed {
    iframe {
      left: -2px;
      width: 101%;
    }
  }
}*/