/*
 *  Usage:
 *
<div class="gra-anim">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
 *
 */

$pointSize: 20px;
$pointColor: $gracolor;
$pointsAnimationSpeed: 3s;

@if $la_kit == loaderscss {
  @if $la_typ == gra-anim {
    @keyframes logorotateouter {
      0%   {transform: rotate(0deg);}
      100% {transform: rotate(180deg);}
    }
    @keyframes span2 {
      0%   {top: 0;}
      50%  {top: $pointSize*2;}
      100% {top: 0;}
    }
    @keyframes span3 {
      0%   {left: 0;}
      50%  {left: $pointSize*2;}
      100% {left: 0;}
    }
    @keyframes span4 {
      0%   {top: $pointSize*4;}
      50%  {top: $pointSize*2;}
      100% {top: $pointSize*4;}
    }
    @keyframes span5 {
      0%   {left: $pointSize*4;}
      50%  {left: $pointSize*2;}
      100% {left: $pointSize*4;}
    }

    @if $la_pls {
      .pls_wrapper {
        .pls_animation {
          .animation {
            .gra-anim {
              position: relative;
              height: $pointSize*5;
              width: $pointSize*5;
              animation: logorotateouter $pointsAnimationSpeed/2 infinite;
              animation-timing-function: linear;

              span {
                width: $pointSize;
                height: $pointSize;
                border-radius: 50%;
                background-color: $pointColor;
                position: absolute;
                display: block;
              }
              span:nth-of-type(1) {
                left: $pointSize*2;
                top: $pointSize*2;
              }
              span:nth-of-type(2) {
                left: $pointSize*2;
                animation: span2 $pointsAnimationSpeed infinite;
              }
              span:nth-of-type(3) {
                top: $pointSize*2;
                animation: span3 $pointsAnimationSpeed infinite;
              }
              span:nth-of-type(4) {
                left: $pointSize*2;
                top: $pointSize*4;
                animation: span4 $pointsAnimationSpeed infinite;
              }
              span:nth-of-type(5) {
                left: $pointSize*4;
                top: $pointSize*2;
                animation: span5 $pointsAnimationSpeed infinite;
              }
            }
          }
        }
      }
    } @else {
      .gra-anim {
        position: relative;
        height: $pointSize*5;
        width: $pointSize*5;
        animation: logorotateouter $pointsAnimationSpeed/2 infinite;
        animation-timing-function: linear;

        span {
          width: $pointSize;
          height: $pointSize;
          border-radius: 50%;
          background-color: $pointColor;
          position: absolute;
          display: block;
        }
        span:nth-of-type(1) {
          left: $pointSize*2;
          top: $pointSize*2;
        }
        span:nth-of-type(2) {
          left: $pointSize*2;
          animation: span2 $pointsAnimationSpeed infinite;
        }
        span:nth-of-type(3) {
          top: $pointSize*2;
          animation: span3 $pointsAnimationSpeed infinite;
        }
        span:nth-of-type(4) {
          left: $pointSize*2;
          top: $pointSize*4;
          animation: span4 $pointsAnimationSpeed infinite;
        }
        span:nth-of-type(5) {
          left: $pointSize*4;
          top: $pointSize*2;
          animation: span5 $pointsAnimationSpeed infinite;
        }
      }
    }

  }
}
