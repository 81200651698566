/* ########### LAZYLOADING ANIMATIONCONFIG ############## */

$ll_animation: opacity;  //opacity, zoom, topzoom, bottomzoom, opacityzoom
$ll_animation-speed-translate: 500ms;
$ll_animation-speed-opacity: 800ms;
$ll_animation-translate: 50%;




.lazyloadinganimation {
  @if $ll_animation == opacity{
    opacity: 0;
    transition: opacity $ll_animation-speed-opacity;
  } @else if $ll_animation == zoom {
    transform: scale(0);
    transition: transform $ll_animation-speed-translate;
  } @else if $ll_animation == topzoom {
    transform: translateY(-$ll_animation-translate) scale(0);
    transition: transform $ll_animation-speed-translate;
  } @else if $ll_animation == bottomzoom {
    transform: translateY($ll_animation-translate) scale(0);
    transition: transform $ll_animation-speed-translate;
  } @else if $ll_animation == opacityzoom {
    transform: scale(0);
    opacity: 0;
    transition: transform $ll_animation-speed-translate, opacity $ll_animation-speed-opacity;
  }
}

.lazyloaded {
  @if $ll_animation == opacity{
    opacity: 1;
  } @else if $ll_animation == zoom {
    transform: scale(1);
  } @else if $ll_animation == topzoom or  $ll_animation == bottomzoom{
    transform: translateY(0) scale(1);
  } @else if $ll_animation == opacityzoom {
    transform: scale(1);
    opacity: 1;
  }
}



img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

.lazysizewrapper {
  position: relative;
  .animation {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}