#cookieConsent {
  h1, h2, h3, h4, p {
    span {
      color: $white;
    }
  }
}

/*
Additional css is created under _cookieLaw.scss
Here are just the variables for klaro
You can use variables of _settings.scss
*/

/* Border, shadows, ... */
$cm-box-shadow-dark-md: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
$cm-box-shadow-light-sm: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
$cm-box-shadow-dark-sm: 0 2px 5px 0 rgba(0, 0, 0, 0.33);

$cm-border-radius: 4px;
$cm-border-dark: 1px solid #555;

/* Colors */
$cm-font-color-dark: #eee;
$cm-tab-color: #2581c4;
$cm-font-color-dark-deemphasized: #999;
$cm-bg-dark: $grablack;
$cm-gray-medium: #aaa;
$cm-gray-light: #eee;
$cm-white: #fff;

$cm-blue1: #2581c4;
$cm-blue2: scale-color($cm-blue1, $lightness: 20%);
$cm-blue3: scale-color($cm-blue2, $lightness: 20%);
$cm-blue4: scale-color($cm-blue3, $lightness: 20%);

$cm-green1: $gracolor;
$cm-green2: scale-color($cm-green1, $lightness: 20%);
$cm-green3: scale-color($cm-green2, $lightness: 20%);
$cm-green4: scale-color($cm-green3, $lightness: 20%);

$cm-red1: #da2c43;
$cm-red2: scale-color($cm-red1, $lightness: 20%);
$cm-red3: scale-color($cm-red2, $lightness: 20%);
$cm-red4: scale-color($cm-red3, $lightness: 20%);

$cm-yellow1: #f2d600;

$vars: (
        green1: $cm-green1,
        green2: $cm-green2,
        green3: $cm-green3,
        font-color-dark: $cm-font-color-dark,
        blue1: $cm-blue1,
        blue2: $cm-blue2,
        blue3: $cm-blue3,
);

/* Spacing */
$cm-space: (
        xs: 4px,
        sm: 8px,
        md: 12px,
        lg: 24px,
        xl: 48px,
        xxl: 96px
);

/* Breakpoints */
$cm-desktop: 1024px;
$cm-mobile: 384px;
$cm-tablet: 768px;

@mixin var($property, $varName) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, map-get($vars, $varName));
}
