section.back {
  .grid-x {
    a {
      color: $grablack;
      display: block;
      text-align: center;
      border-top: 1px solid #9b9b9b;
      margin-top: $abstand;
      padding-top: $abstand/2;
      @include breakpoint(small only) {
        margin-top: $abstandSmall;
        padding-top: $abstandSmall/2;
      }
      font-size:20px;
      opacity:0.5;
      font-weight: 600;
      transition: all 250ms;
      &:hover {
        opacity: 1;
      }
    }
  }
}