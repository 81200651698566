section.articlelist {
  .cell {
    position: relative;
    padding-bottom: $abstand;
    @include breakpoint(small only) {
      padding-top: $abstandSmall;
    }
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 40;
  }
  .preline {
    font-size: 17px;
    color: #919191;
    margin-top: $abstand - 10;
    margin-bottom: 5px;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
    @include breakpoint(medium down) {
      font-size: 20px;
    }
    @include breakpoint(small only) {
      font-size: 16px;
    }
  }
  .headline {
    font-size: 27px;
    color: $gracolor;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 25px;
    @include breakpoint(medium down) {
      font-size: 22px;
    }
    @include breakpoint(small only) {
      font-size: 17px;
    }
  }
}