section.contactCallToAction {
  .socialchip {
    padding-top: $abstand - 15px;
    @include breakpoint(small only) {
      padding-top: $abstandSmall - 15px;
    }
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 40px;
    flex-wrap: wrap;
    margin-bottom: -15px;
    margin-left: -15px;
    a {
      margin: 15px 0 15px 15px;
      text-align: center;
      background-color: #E4E4E4;
      transition: all 250ms;
      display: block;
      width: 60px;
      font-size: 40px;
      border-radius: 2px;
      i {
        color: #969696;
        position: relative;
      }
      &:hover {
        background-color: $gracolor;
        i {
          color: $white;
        }
      }
    }
  }
  .socialmedia {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 40px;
    position: relative;
    top: -3px;
  }
  .contact {
    padding-bottom: 0;
    display: flex;
    flex: 1;
    .grid-container {
      display: flex;
      flex: 1;
      .grid-x {
        margin: 0;
        flex: 1;
        .cell {
          display: flex;
          width: 100%;
          padding: 0;
          .form {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
          }
        }
      }
    }
  }
  .flex-nowrap {
    display: flex;
    flex-flow: column nowrap;
  }
  .grid-x-margin-top {
    margin-top: $abstand - 10;
    @include breakpoint(small only) {
      margin-top: $abstandSmall;
    }
  }
  .last-element , .form .form-footer{
    margin-top:auto;
    padding-bottom: $abstand + 25;
  }
  .form .form-footer {
    margin-bottom: 0;
  }
  a.button {
    display: inline-flex;
    white-space: nowrap;
  }
}