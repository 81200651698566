@if $ddm_offCanvas {
  main {
    z-index: 49;
    position: relative;
    background-color: white;
    left: 0;
  }
  .mainwrapper {
    z-index: 49;
    position: relative;
    left: 0;

    &.transition {
      transition: all $ddm_onCanvas-animation-speed-open ease;

      main {
        transition: all $ddm_onCanvas-animation-speed-open ease;
      }
    }
  }
  body {
    overflow-x: hidden;
  }
}

nav {
  @if $onlyCanvas != true {
    z-index: 51;
    position: relative;
  }

  @if $ddm_fullwidth {
    background-color: $ddm_backgroundcolormenu;
  }

  .wrapper {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(79, 79, 79, 0) 100%
    );
    transition: all 750ms ease-in-out;
    float: left;
    width: 100%;
    padding-bottom: 0;
    height: 68px;
    position: relative;
    .logo {
      width: 165px;
      float: right;
      margin: 14px 50px 0 0;
      position: relative;
      z-index: 1001;
      a {
        .st0 {
          transition: all 300ms;
        }
      }

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin: 0 30px 0 0;
      }
    }
  }

  li {
    list-style: none;
    padding: 0 0;
    margin: 0 0;
    display: block;
  }

  a {
    padding: $ddm_paddingmenu;
    white-space: nowrap;
    text-decoration: none;
    font-family: $ddm_font-family;
    -webkit-font-smoothing: antialiased;
    font-weight: $ddm_font-weight;
    line-height: $ddm_line-height;
  }

  .logo {
    height: 100%;

    a {
      padding: 0;
      display: block;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100%;
      }
    }
  }

  @if $pls_show_menu {
    &.zindex {
      z-index: 1002;
    }
  }
}

body.mobile-menu-open,
html.mobile-menu-open {
  @media screen and (max-width: $ddm_breakmobile) {
    overflow-y: hidden;
  }
}

span.point {
  display: block;
  width: 6px;
  height: 6px;
  margin: 6px auto;
  text-align: center;
  background-color: white;
  border-radius: 100%;
}

//turn animation
.menu-wrapper {
  & > .grid-container > .menu-list {
    & > li {
      transform-origin: center center 0;
      @for $i from 0 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 100ms + 500ms;
          transition-delay: $i * 100ms + 500ms;
        }
      }
      transition: opacity 600ms ease-in-out;
      opacity: 0;
    }
  }
  &.open > .grid-container > .menu-list {
    & > li {
      position: static;
      animation-name: turn-in-animation;
      animation-duration: 600ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
    &.flying-out > li {
      animation-name: turn-out-animation;
      animation-duration: 150ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      //reduce delay
      @for $i from 0 through 20 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 0.04s;
          transition-delay: $i * 0.04s;
        }
      }

      opacity: 1;
    }
  }
}

@keyframes turn-in-animation {
  0% {
    display: none;
    visibility: hidden;
    transform: matrix3d(
      0.3332,
      -0.17,
      -0.94,
      0,
      0.17,
      0.8526,
      0.5,
      0,
      0.94,
      -0.5,
      0.2958,
      0,
      50,
      0,
      0,
      1
    );
  }
  1% {
    display: block;
    visibility: visible !important;
    transform: matrix3d(
      0.3332,
      -0.17,
      -0.94,
      0,
      0.17,
      0.8526,
      0.5,
      0,
      0.94,
      -0.5,
      0.2958,
      0,
      50,
      0,
      0,
      1
    );
  }
  99% {
    display: block !important;
    opacity: 1;
    visibility: visible !important;
    transform: none;
  }
  100% {
    display: block !important;
    opacity: 1;
    visibility: visible !important;
    transform: none;
  }
}
@keyframes turn-out-animation {
  from {
    display: block !important;
    opacity: 1;
    visibility: visible !important;
    transform: none;
  }
  to {
    display: block;
    opacity: 0;
    visibility: visible;
    transform: matrix3d(
      0.3332,
      -0.17,
      -0.94,
      0,
      0.17,
      0.8526,
      0.5,
      0,
      0.94,
      -0.5,
      0.2958,
      0,
      50,
      0,
      0,
      1
    );
  }
}

.menu-list {
  //menu list style
  //position: relative;
  display: inline-block;
  &.menu-open {
    a {
      color: #858585;
      &:hover {
        text-decoration: none;
        color: #f3f3f3;
      }
    }
  }

  & > li {
    @include breakpoint(small down) {
      margin-bottom: 0 !important;
      padding-top: 28px !important;
    }

    margin-bottom: 34px;

    .menu-plus {
      display: inline-block;
      position: relative;
      left: 5%;
      top: -4px;
      height: 17px;
      width: 17px;
      @include breakpoint(small down) {
        top: -1px;
      }

      span {
        position: absolute;
        top: calc(50%);
        width: 17px;
        height: 3px;
        display: inline-block;
        background: #be7956;
        margin: 0;
        padding: 0;
        border-radius: 12px;
      }
      .menu-plus-line-1 {
        transform: scaleY(1) rotateZ(90deg);
        transition: width 300ms ease-in-out, transform 300ms ease-in-out;
      }
      .menu-plus-line-2 {
      }
    }

    &.open {
      .menu-plus {
        span {
        }
        .menu-plus-line-1 {
          transform: scaleY(0) rotateZ(90deg);
        }
        .menu-plus-line-2 {
        }
      }

      & > a {
        color: #f3f3f3 !important;
      }
    }

    //top menu link style
    a {
      font-size: 37px;
      transition: color ease-in-out 100ms;
      color: #f3f3f3;
      letter-spacing: 1.16px;

      @include breakpoint(small down) {
        &.notransition {
          & > span {
            font-size: 22px !important;
            color: #ffffff;
            letter-spacing: 0.69px;
          }
          & > span {
            font-size: 22px !important;
            color: #ffffff;
            letter-spacing: 0.69px;
          }
          font-size: 22px !important;
          color: #ffffff;
          letter-spacing: 0.69px;
        }
        font-size: 22px !important;
        color: #ffffff;
        letter-spacing: 0.69px;
      }

      &:hover {
        text-decoration: none;
        color: #858585;
      }
    }

    //child menu wrapper style
    & > .menu-point-child {
      position: absolute;
      display: block;
      zoom: 1;
      left: 55%;
      top: 0;
      line-height: 0;
      overflow: hidden;
      transition: height 650ms ease;

      padding-top: 0;
      visibility: hidden;
      @include breakpoint(medium down) {
        position: relative;
        top: 0;
        left: 0;
        padding-left: 0;
        & > ul {
          margin-left: 0;
        }
      }

      //when open
      &.open {
        transition: height 650ms ease;
        padding-top: 15px;
        @include breakpoint(medium down) {
          padding-top: 0;
          position: relative;
          top: 0;
          left: 0;
          padding-left: 12px;
          & > ul {
            margin-left: 0;
          }
        }
        visibility: visible;
        height: auto;
        max-height: 1000px;
        //child menu list style when open
        & > ul > li {
          opacity: 1;
          transform: none;
          @for $i from 0 through 20 {
            &:nth-child(#{$i}) {
              transition: visibility 0s 0s, transform 600ms $i * 0.1s ease-out,
                opacity 300ms $i * 0.15s linear;
            }
          }
          //sub menu link style
          & > a {
            font-size: 20px;
            color: #f3f3f3;
            letter-spacing: 3.42px;
            text-transform: uppercase;
            font-weight: bold;
            @include breakpoint(small down) {
              font-size: 14px;
              letter-spacing: 1.4px;
            }

            &:hover {
              color: #858585;
            }
          }
        }
      }

      //sub list
      & > .menu-list {
        //get delay for every child

        //sub list list
        & > li {
          transform: translateX(-70px);
          //last number is max amount of items in list i don't think there will be more than 20 elements

          @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
              transition: visibility 0s 0s, transform 0s 0s linear,
                opacity 0s 0s linear;
            }
          }

          opacity: 0;
          margin-bottom: 61px;
          &:last-child {
            margin-bottom: 0;
          }
          &:first-child {
            @include breakpoint(small down) {
              margin-top: 0;
            }
          }
          @include breakpoint(medium down) {
            margin-bottom: 27px;
          }

          //sub menu link style
          & > a {
            @include breakpoint(small down) {
              font-size: 14px !important;
            }

            font-size: 20px;
            color: #f3f3f3;
            letter-spacing: 3.42px;
          }
        }
      }
    }
  }
}

@keyframes contact-info-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.menu-wrapper {
  position: relative;
  top: 12px;

  bottom: 10px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  right: 0;
  &.open {
    display: inherit !important;
  }
  & > .grid-container {
    transition: height 100ms ease-in-out;
    top: 5%;
    position: relative;
  }

  &.open > .menu-contact-info-container {
    animation: contact-info-fade-in 400ms linear 500ms forwards;

    & > .grid-container {
      transition: opacity 2000ms linear 0.2s;
    }
  }

  & > .menu-contact-info-container {
    pointer-events: none;
    opacity: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 0%,
      #222222 66%
    );
    padding-top: 125px;
    transition: opacity 2000ms linear;
    & > .grid-container {
      position: relative;
      padding-bottom: 25px;
      transition: opacity 2000ms linear;
    }

    span {
      margin-left: 1.25rem;
      padding-right: 1.25rem;
      border-right: 1px solid #a2a2a2;
      &:last-child {
        border-right: 0;
      }
    }
    @include breakpoint(small down) {
      position: fixed;
    }

    .menu-contact-info {
    }
  }
  @include breakpoint(small only) {
    left: 0;
    top: 0;
  }
}

@media screen and (max-width: $ddm_breakmobile) {
  html,
  body {
    overflow-x: hidden;
  }

  .mainwrapper {
    &.rotatePusher,
    &.rotateIn3d,
    &.rotateOut3d,
    &.scaleDownPusher,
    &.scaleRotatePusher,
    &.openDoor,
    &.delayed3dRotate {
      perspective: 1500px;
    }

    &.rotateIn3d,
    &.rotateOut3d,
    &.delayed3dRotate {
      perspective-origin: 0 50%;
      @if $ddm_canvasDirectionRight {
        perspective-origin: 100% 50%;
      } @else {
        perspective-origin: 0 50%;
      }
    }

    &.scaleDownPusher,
    &.openDoor,
    &.backgroundPushSide,
    &.backgroundPushTop,
    &.backgroundOverlay {
      z-index: 0;
    }
  }
  main {
    @if $ddm_canvasAnimation ==
      backgroundPushSide or
      $ddm_canvasAnimation ==
      backgroundPushTop or
      $ddm_canvasAnimation ==
      backgroundOverlay
    {
      z-index: 47;
    }

    &.rotatePusher {
      transform-style: preserve-3d;
      perspective: 1000px;
      transform: rotateY(0deg);
      @if $ddm_canvasDirectionRight {
        transform-origin: 100% 0;
        &.open {
          transform: rotateY(15deg);
        }
      } @else {
        transform-origin: 0 50%;
        &.open {
          transform: rotateY(-15deg);
        }
      }
    }

    &.scaleDownPusher {
      transform-style: preserve-3d;
      perspective: 1000px;
      transform: translate3d(0, 0, 0);

      &.open {
        transform: translate3d(0, 0, -150px);
        z-index: 0;
      }
    }

    &.scaleRotatePusher {
      transform-style: preserve-3d;
      perspective: 1000px;
      transform: translate3d(0, 0, 0) rotateY(0deg);

      &.open {
        z-index: 0;
        @if $ddm_canvasDirectionRight {
          transform: translate3d(-100px, 0, -600px) rotateY(20deg);
        } @else {
          transform: translate3d(100px, 0, -600px) rotateY(-20deg);
        }
      }
    }

    &.openDoor {
      transform-style: preserve-3d;
      perspective: 1000px;
      transform-origin: 100% 50%;
      transform: rotateY(0deg);
      @if $ddm_canvasDirectionRight {
        transform-origin: 0 50%;
      } @else {
        transform-origin: 100% 50%;
      }

      &.open {
        @if $ddm_canvasDirectionRight {
          transform: rotateY(10deg);
        } @else {
          transform: rotateY(-10deg);
        }
        z-index: 0;
      }
    }
  }

  nav {
    position: relative;

    &.rotateIn3d,
    &.rotateOut3d,
    &.delayed3dRotate {
      transform-style: preserve-3d;
      perspective: 1500px;
    }

    &.delayed3dRotate {
      perspective: 1000px;
    }

    &.scaleUp {
      perspective: 1500px;
      @if $ddm_canvasDirectionRight {
        perspective-origin: 100% 300px;
      } @else {
        perspective-origin: 0 300px;
      }
    }

    &.onCanvas {
      z-index: 50;
      width: 100%;
      position: fixed;
    }

    z-index: 48;

    li {
      .arrow {
        display: block;
      }
    }

    li > ul {
      overflow: hidden;
      transition: all $ddm_onCanvas-animation-speed-height ease;
    }
  }

  .placeholder {
    display: none;
  }

  @if $ddm_canvasAnimation ==
    backgroundPushSide or
    $ddm_canvasAnimation ==
    backgroundPushTop or
    $ddm_canvasAnimation ==
    backgroundOverlay
  {
    .menu-animation-wrapper {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
      @if $onlyCanvas {
        z-index: 48;
      } @else {
        z-index: 0;
      }

      top: 0;
      pointer-events: none;

      .menu-animated-background {
        position: absolute;
        top: -358px;
        @if $ddm_canvasDirectionRight {
          right: -358px;
        } @else {
          left: -358px;
        }
        width: 800px;
        height: 800px;
        background-color: $ddm_bgAnimation-color;
        border-radius: 50%;
        pointer-events: none;
        opacity: 0;
        transform: scale(0);
        z-index: 0;
        transition: all 500ms ease-in;

        &.open {
          opacity: 1;
          @include breakpoint(medium down) {
            transform: scale(3);
          }
          transform: scale(4);
        }
      }
    }
  }
}

.mobile-menu-open {
  .navbar-scroll {
    .wrapper {
      transition-delay: -50;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(79, 79, 79, 0) 100%
      );
      background-size: contain;
    }
  }
}

@include breakpoint(small only) {
  span.hold {
    margin: 5px auto;
  }
  nav .wrapper .logo {
    width: 145px;
    margin: 16px 20px 0 0;
  }
  button.hamburger {
    padding: 18px 20px 15px;
  }
}

nav.pagetransition {
  .wrapper {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(79, 79, 79, 0) 100%
    ) !important;

    .hamburger--graustich {
      .hamburger-box {
        .hamburger-1,
        .hamburger-2,
        .hamburger-3,
        .hamburger-4,
        .hamburger-5 {
          background-color: $gracolor !important;
        }
      }
    }

    .logo {
      a {
        .st0 {
          fill: $gracolor !important;
        }
      }
    }
  }
}

nav.navbar-scroll {
  .wrapper {
    background: $gracolor;

    .hamburger--graustich {
      .hamburger-box {
        .hamburger-1,
        .hamburger-2,
        .hamburger-3,
        .hamburger-4,
        .hamburger-5 {
          background-color: $white;
        }
      }
    }

    .logo {
      a {
        .st0 {
          fill: $white;
        }
      }
    }
  }
}

body.mobile-menu-open {
  nav {
    .wrapper {
      .hamburger--graustich {
        .hamburger-box {
          .hamburger-1,
          .hamburger-2,
          .hamburger-3,
          .hamburger-4,
          .hamburger-5 {
            background-color: $white;
          }
        }
      }

      .logo {
        a {
          .st0 {
            fill: $white;
          }
        }
      }
    }
  }
}

@keyframes child-menu-height-animation {
  0% {
    visibility: hidden;
    max-height: 0;
  }
  99% {
    visibility: hidden;
    max-height: 1000px;
  }
  100% {
    visibility: visible;
  }
}
