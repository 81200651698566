.secmenu {
  position: fixed;
  width: 100%;
  top: 67px;
  background-color: $white;
  z-index: 48;
  opacity: 0;
  transition: opacity 750ms ease-in-out;
  &.navbar-scroll{
    transition: opacity 600ms ease-in-out 150ms;
    opacity: 1;
  }
  .arrowL, .arrowR {
    position: absolute;
    width: 50px;
    height: 64px;
    top: 0;
    z-index: 49;
    cursor: pointer;
    opacity: 1;
    transition: opacity 250ms;
    &.deactivate {
      opacity: 0.3;
    }
  }

  .arrowL {
    left: 0;
    text-align: right;
    i {
      text-align: center;
      font-size: 40px;
      top: 13px;
      position: relative;
      left: 2px;
    }
    span:nth-child(1) {
      width: 15px;
      height: 2px;
      display: block;
      background-color: $black;
      left: 24px;
      top: 28px;
      transform: rotate(-45deg);
      position: relative;
    }
    span:nth-child(2) {
      width: 15px;
      height: 2px;
      display: block;
      background-color: $black;
      left: 24px;
      top: 36px;
      transform: rotate(45deg);
      position: relative;
    }
  }

  .arrowR {
    right: 0;
    text-align: left;
    i {
      text-align: center;
      font-size: 40px;
      top: 13px;
      position: relative;
      right: 2px;
    }
    span:nth-child(1) {
      width: 15px;
      height: 2px;
      display: block;
      background-color: $black;
      right: -11px;
      top: 28px;
      transform: rotate(45deg);
      position: relative;
    }
    span:nth-child(2) {
      width: 15px;
      height: 2px;
      display: block;
      background-color: $black;
      right: -11px;
      top: 36px;
      transform: rotate(-45deg);
      position: relative;
    }
  }

  ul {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
    white-space: nowrap;
    padding: 0 50px;
    position: relative;
    left: 0;
    height: 64px;
    @include breakpoint(medium only) {
      width: 250%;
      padding: 0;
    }
    @include breakpoint(small only) {
      width: 500%;
      padding: 0;
    }
    li {
      list-style: none;
      width: 20%;
      text-align: center;
      float: left;
      a {
        display: block;
        padding: 16px;
        // font-size:20px; @ _menu.scss and _menu-alwayshow
        opacity:0.5;
        font-weight: 500;
        //font-family: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
        font-family: Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;
        color: $grablack;
        text-decoration: none;
        line-height: 1.6;
      }
      &.active {
        a {
          opacity:1;
        }
      }
    }
  }

  .hr {
    width: 100%;
    display: block;
    padding: 0 25px 0;
    background-color: $white;
    position: relative;
    clear: both;
    .gray {
      height: 2px;
      width: 100%;
      background-color: $grablack;
      opacity:0.5;
    }
    .black {
      background-color: $grablack;
      position: absolute;
      width: 0;
      left: 0;
      height: 2px;
      top: 0;
    }
  }
}
.secmenu.transition {
  ul {
    transition: left 300ms;
    li {
      a {
        transition: opacity 300ms;
      }
    }
  }
  .hr {
    .black {
      transition: width 300ms, left 300ms;
    }
  }
}