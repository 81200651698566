/* ########### DROPDOWNMENU CONFIG ############## */

$ddm_breakmobile: '1023px';
$ddm_fullwidth: true;
$ddm_height: 67px;
// Do all NAV CSS critical that needed for show menu at pagetransition
$ddm_alwaysshow:true;

/*Nav Font */
$ddm_font-family: Roboto,sans-serif;
$ddm_font-weight: 400;
$ddm_line-height: 1.6;

/* First Level Menu */
$ddm_paddingmenu: 17px 15px;
$ddm_textcolormenu: white;
$ddm_textcolormenu-hover: white;
$ddm_backgroundcolormenu: transparent;
$ddm_backgroundcolormenu-hover: $gramenucolor;
$ddm_fontsizemenu: 16px;
/* Submenu */
$ddm_top: $ddm_height;
$ddm_paddingsubmenu: 10px 20px;
$ddm_textcolorsubmenu: white;
$ddm_textcolorsubmenu-hover: white;
$ddm_backgroundcolorsubmenu: white;
$ddm_backgroundcolorsubmenu-hover: white;
$ddm_boxshadow: 0 2px 2px 0 rgba(0,0,0,.3);
$ddm_fontsizesubmenu: 16px;
/*
for different Animations look at:
https://daneden.github.io/animate.css/

Materialstyle: materialDropdown
*/
$ddm_1_submenu-animation: flipInX;
$ddm_1_submenu-animation-speed: 1s;
$ddm_2_submenu-animation: rollIn;
$ddm_2_submenu-animation-speed: 1s;



/* ########### CANVAS CONFIG ############## */

/* CANVAS Main */
$onlyCanvas: true;
$ddm_canvas-fullscreen: true;
$ddm_offCanvas: false;
$ddm_canvasDirectionRight: false;


/* On Canvas */
$ddm_onCanvas-padding-left-right: 50px;
$ddm_onCanvas-padding-top-bottom: 10px;
$ddm_onCanvas-animation-speed-arrows: 0.5s;
$ddm_onCanvas-animation-speed-height: 0.5s;
$ddm_onCanvas-animation-speed-open: 0.5s;
/* First level Canvas */
$ddm_onCanvas-backgroundcolor: $gramenucolor;
$ddm_onCanvas-backgroundcolor-hover: $gramenucolor;
$ddm_onCanvas-textcolor: white;
$ddm_onCanvas-textcolor-hover: white;
/* Submenu Canvas */
$ddm_onCanvas-backgroundcolor-submenu: $gramenucolor;
$ddm_onCanvas-backgroundcolor-submenu-hover: $gramenucolor;
$ddm_onCanvas-textcolor-submenu: white;
$ddm_onCanvas-textcolor-submenu-hover: white;

/* Canvas First Level Animation */
$ddm_canvasFirstLevelAnimation: side;   // side, top, opacity, false
$ddm_canvasFirstLevelAnimationCount: 10;
$ddm_canvasFirstLevelAnimationDelayStart: 0.25;
$ddm_canvasFirstLevelAnimationDelayPlus: 0.05;
$ddm_canvasFirstLevelAnimationSpeedTransition: 0.5s;
$ddm_canvasFirstLevelAnimationSpeedOpacity: 0.5s;
$ddm_canvasFirstLevelAnimationTransition: 100%;
/*
example for offCanvasAnimation
https://tympanus.net/Development/SidebarTransitions/
*/
//push, overlay, slideAlong, reverseSlideOut, rotatePusher, rotateIn3d, rotateOut3d, scaleDownPusher, scaleUp, scaleRotatePusher, openDoor, fallDown, delayed3dRotate, topToBottom, backgroundPushSide, backgroundPushTop, backgroundOverlay
$ddm_canvasAnimation: scaleDownPusher;





$ddm_canvasAnimationJS: $ddm_offCanvas;
@if $ddm_offCanvas {
  $ddm_canvasAnimationJS: $ddm_canvasAnimation;
}
@if $onlyCanvas {
  $ddm_breakmobile: '99999px'
}

$ddm_bgAnimation-color: transparent;
@if $ddm_canvasAnimation == 'backgroundPushSide' or $ddm_canvasAnimation == 'backgroundPushTop' or $ddm_canvasAnimation == 'backgroundOverlay' {
  $ddm_bgAnimation-color: $ddm_onCanvas-backgroundcolor;
  $ddm_canvas-fullscreen: true;
}





/* ########### Hamburger CONFIG ############## */
// ==================================================
$hamburger-padding-x                       : 30px;
$hamburger-padding-y                       : 16px;
$hamburger-layer-width                     : 30px;
$hamburger-layer-height                    : 4px;
$hamburger-layer-spacing                   : 6px;
$hamburger-layer-color                     : #FFF;
$hamburger-layer-border-radius             : 4px;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;
$hamburger-animation: graustich;
// for other Hamburger animations looks at https://github.com/jonsuh/hamburgers //material Design: material

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);







// Hamburger left or right
$hamburger-float-left: true;
@if $ddm_canvasDirectionRight {
  $hamburger-float-left: false;
  $hamburger-animation: unquote( $hamburger-animation + '-r');
}


button.hamburger {
  outline: none;
  display: none;
  z-index: 1001;
  position: relative;
  background-color: transparent;
  border: 0;
  @media screen and (max-width: $ddm_breakmobile) {
    display: block;
  }
  padding: 18px 40px 15px;
  @if $hamburger-float-left {
    float: left;
  } @else {
    float: right;
  }
}

$hamburger-types: (
  //3dx,
  //3dx-r,
  //3dy,
  //3dy-r,
  //arrow,
  //arrow-r,
  //arrowalt,
  //arrowalt-r,
  //arrowspin,
  //arrowspin-r,
  //boring,
  //collapse,
  //collapse-r,
  //elastic,
  //elastic-r,
  //emphatic,
  //emphatic-r,
        $hamburger-animation,
  //material-r,
  //slider,
  //slider-r,
  //spring,
  //spring-r,
  //stand,
  //stand-r,
  //spin,
  //spin-r,
  //squeeze,
  //vortex,
  //vortex-r
);


$ddm_js_conifg: (
        hamburgerAnimation: $hamburger-animation,
        ddmBreakmobile: #{str-replace($ddm_breakmobile, 'px', '')},
        ddmCanvasAnimation: $ddm_canvasAnimationJS,
        ddmCanvasDirectionRight: $ddm_canvasDirectionRight,
        onlyCanvas: $onlyCanvas
);