section.referenzen {
  transition: all 500ms ease;
  .refinfowrapper {
    .refinfo {
      visibility: hidden;

    }
  }
  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
    cursor: pointer;
    z-index: 10;
    background-image: url("../10_images/02_elements/close.svg");
    top: -50px;
    @include breakpoint(medium only) {
      right: 25px;
    }
    @include breakpoint(small only) {
      right: 20px;

    }
  }
  .refinfo {
    transition: opacity 500ms ease;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    width: 100%;
    opacity: 0;
    padding-top: 30px;
    @include breakpoint(medium down) {
      padding-top: 20px;
    }

    .grid-x {
      position: relative;
    }
    p {
      margin-top: 20px;
    }
    h2.h1 {
      margin-top: 0;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 20px;
    background-color: rgba(51,51,51,.95);
    transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    h2 {
      margin-top: 0;
    }
    h2,h5 {
      color: $white;
    }
  }
  .ref {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    .lazysizewrapper {
      transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover {
      .overlay {
        transition: opacity 0.8s cubic-bezier(51,51,51,.95);
        opacity: 1;
      }
      .lazysizewrapper {
        transform: scale3d(1.25, 1.25, 1.25);
      }
    }
  }
}
section.referenzen > .grid-container > .grid-x > .cell {
  margin-bottom: 50px;
  transition: all 500ms ease;
}